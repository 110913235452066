import React from 'react';
import { Box } from '@material-ui/core';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import Banner3Img from 'src/images/daiseikaicampaign_202404/section/campaign/amazone_3.png';
import TitleSection from './TitleSection';
import styles from './SectionCampaign.module.scss';

const CAMPAIGNS = [
  {
    href: 'https://www.amazon.co.jp/b?node=8215625051',
    src: Banner3Img,
    alt: 'Amazon Cashback',
  },
];

export default function SectionCampaign({ id, number, text }) {
  return (
    <section className={styles.section} id={id}>
      <TitleSection number={number} text={text} />
      <p className={styles.text}>
        お得なキャンペーンを開催中。このチャンスに、
        <br />
        ぜひペイディでお買い物をお楽しみください。
      </p>
      <Box className={styles.container}>
        {CAMPAIGNS.map((campaign) => (
          <OutboundLink
            href={campaign.href}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={campaign.src}
              alt={campaign.alt}
              width={311}
              height={336}
            />
          </OutboundLink>
        ))}
      </Box>
    </section>
  );
}

import React, { useCallback, useState, useEffect } from 'react';
import { useLocation } from '@reach/router';
import cx from 'classnames';
import Slider from 'react-slick';
import { Box, Container, Hidden, Typography } from '@material-ui/core';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import {
  Layout,
  ButtonOutboundLink,
  BadgesByDevice,
  PinkBadge,
} from 'src/components';
import SectionCampaignFooter from 'src/components/Merchant/Campaign/Newlife/SectionCampaignFooter';
import PaidyLogoSvg from 'src/images/paidy-logo.svg';
import BannerImg from 'src/images/daiseikaicampaign_202404/banner.jpg';
import BannerSpImg from 'src/images/daiseikaicampaign_202404/banner-sp.jpg';
import ScrollImg from 'src/images/campaign-202303/scroll.png';
import Video1ThumbnailVideo from 'src/images/daiseikaicampaign_202404/video-1-thumbnail.mp4';
import Video2ThumbnailImg from 'src/images/daiseikaicampaign_202404/video-2-thumbnail.png';
import Video3ThumbnailImg from 'src/images/daiseikaicampaign_202404/video-3-thumbnail.png';
import Cast1Avartar from 'src/images/daiseikaicampaign_202404/cast1-avartar.png';
import Cast2Avartar from 'src/images/daiseikaicampaign_202404/cast2-avartar.png';
import Step1Img from 'src/images/daiseikaicampaign_202404/section/what_paidy/card1.png';
import Step2Img from 'src/images/daiseikaicampaign_202404/section/what_paidy/card2.png';
import Step3Img from 'src/images/daiseikaicampaign_202404/section/what_paidy/card3.png';
import QrImg from 'src/images/campaign-202303/qr.png';
import TwitterGreySvg from 'src/images/campaign-202303/icon-x-black.svg';
import TwitterWhiteSvg from 'src/images/campaign-202303/icon-x-white.svg';
import PlayVideoBtnImg from 'src/images/daiseikaicampaign_202404/play-btn.svg';
import PlayVideoBtnLgImg from 'src/images/daiseikaicampaign_202404/play-btn-lg.svg';
import PaidyLogoWhiteSvg from 'src/images/campaign-202303/paidy-logo-white.svg';
import BorderLinearGradientImg from 'src/images/campaign-202303/border-linear-gradient.png';
import SpecialMovieThumbnail2Img from 'src/images/daiseikaicampaign_202404/special-movie-thumbnail-2.png';
import SpecialMovieThumbnail1Video from 'src/images/daiseikaicampaign_202404/special-movie-video-thumbnail-1.mp4';
import { SITE_METADATA, PAIDY_HOST } from 'src/constants';
import SectionCampaign from './daiseikaicampaign_202404/SectionCampaign';
import TitleSection from './daiseikaicampaign_202404/TitleSection';

import styles from '../styles/pages/daiseikaicampaign_202404.module.scss';

const APP_URLS_SP = {
  appStoreUrl: 'https://paidy.onelink.me/GTiS/9x7wdc9y',
  playStoreUrl: 'https://paidy.onelink.me/GTiS/9x7wdc9y',
};

const APP_URLS_PC = {
  appStoreUrl: 'https://paidy.onelink.me/GTiS/9x7wdc9y',
  playStoreUrl: 'https://paidy.onelink.me/KTFT/io3y34pw',
};

const SEOProps = {
  ogImage: `${PAIDY_HOST}/ogp_daiseikaicampaign_202404.jpg`,
  twitterImage: `${PAIDY_HOST}/ogp_daiseikaicampaign_202404.jpg`,
  title: SITE_METADATA.daiseikaicampaign_202404_2.title,
  description: SITE_METADATA.daiseikaicampaign_202404_2.description,
};

const ITEMS = [
  {
    ImgComponent: () => (
      <img alt="step-1" src={Step1Img} width={250} height={362} />
    ),
    key: '1',
  },
  {
    ImgComponent: () => (
      <img alt="step-2" src={Step2Img} width={250} height={362} />
    ),
    key: '2',
  },
  {
    ImgComponent: () => (
      <img alt="step-3" src={Step3Img} width={250} height={362} />
    ),
    key: '3',
  },
];

const SLIDER_SETTINGS = {
  className: 'slider variable-width',
  slidesToShow: 3,
  swipe: false,
  dots: false,
  variableWidth: false,
  autoplay: false,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        swipe: true,
        dots: true,
        variableWidth: true,
        autoplay: true,
      },
    },
  ],
};

// Auto play
const VIDEO_1_EMBEDDED =
  'https://www.youtube.com/embed/gap6xFKpgtM?&autoplay=1';
const VIDEO_2_EMBEDDED =
  'https://www.youtube.com/embed/EBN9Yb_2ZNQ?&autoplay=1';
const VIDEO_3_EMBEDDED =
  'https://www.youtube.com/embed/1q1VoptZW5U?&autoplay=1';
const SPECIAL_MOVIE_EMBEDDED_1 =
  'https://www.youtube.com/embed/VXZtvpbjmgs?&autoplay=1';
const SPECIAL_MOVIE_EMBEDDED_2 =
  'https://www.youtube.com/embed/DfFst46993E?&autoplay=1';

const HEADER_MENU = [
  {
    id: 'section-01',
    number: '01',
    text: 'MOVIES',
    to: '#section-01',
  },
  {
    id: 'section-02',
    number: '02',
    text: 'CAST',
    to: '#section-02',
  },
  {
    id: 'section-03',
    number: '03',
    text: 'SPECIAL CONTENTS',
    to: '#section-03',
    newText: undefined,
    description:
      'ここでしか見ることのできない特別なコンテンツを今すぐチェック。',
    isNew: false,
  },
  {
    id: 'section-04',
    number: '04',
    text: 'CAMPAIGN',
    to: '#section-04',
  },
  {
    id: 'section-05',
    number: '05',
    text: "WHAT'S Paidy",
    to: '#section-05',
  },
];

function generateTwitterShareURLWithText(text, url) {
  const xUrl = new URL('https://twitter.com/intent/tweet');

  xUrl.searchParams.append('text', text);
  xUrl.searchParams.append('url', url);

  return xUrl.toString();
}

const TWITTER_URL_SHARE = generateTwitterShareURLWithText(
  `【ペイディ TVCMスペシャルサイト】\n\nゆりやんレトリィバァさん & 満島真之介さん出演のペイディTVCMをご紹介。CMのメイキング動画、インタビューなど、特別なコンテンツをお楽しみください。\n`,
  'https://paidy.com/daiseikaicampaign_202404'
);

export default function Campaign202303() {
  const [playVideo, setPlayVideo] = useState(false);
  const [playUrl, setPlayUrl] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  const onClickMenu = () => setTimeout(() => setMenuOpen(!menuOpen), 100);

  const onCloseVideoPlayer = useCallback(() => {
    setPlayUrl('');
    setPlayVideo(false);
  }, []);

  const onClickPlayVideo = useCallback(
    (url) => () => {
      setPlayUrl(url);
      setPlayVideo(true);
    },
    []
  );

  useEffect(() => {
    const { hash } = location;

    if (hash.includes('section') && window) {
      setTimeout(() => {
        window.location.href = hash;
      }, 500);
    }
  }, []);

  return (
    <Layout SEOProps={SEOProps} hasNoHeader hasNoFooter>
      {playVideo && (
        <div className={styles.videoPlayer}>
          <button className={styles.btnClose} onClick={onCloseVideoPlayer}>
            X
          </button>
          <iframe
            src={playUrl}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            className={styles.iframe}
          />
        </div>
      )}

      <Hidden xsDown>
        <header className={styles.header}>
          <Container className={styles.container}>
            <nav className={styles.nav}>
              <Link to="/">
                <img alt="logo" src={PaidyLogoSvg} width={100} height={40} />
              </Link>
              <div className={styles.right}>
                {HEADER_MENU.map((menu) => (
                  <Link
                    key={menu.number}
                    className={styles.link}
                    to={menu.to}
                    onClick={onClickMenu}
                  >
                    <span className={styles.number}>{menu.number}</span>
                    <span className={styles.span}>{menu.text}</span>
                    <Box display="inline-block" ml={1}>
                      {menu.isNew && <PinkBadge />}
                    </Box>
                  </Link>
                ))}
              </div>
            </nav>
          </Container>
        </header>
      </Hidden>

      <Hidden smUp>
        {!menuOpen ? (
          <header className={styles.headerMobile}>
            <nav className={styles.nav}>
              <Link to="/">
                <img alt="logo" src={PaidyLogoSvg} width={100} height={40} />
              </Link>
              <button className={styles.menu} onClick={onClickMenu} />
            </nav>
          </header>
        ) : (
          <header className={styles.headerMobileActive}>
            <nav className={styles.nav}>
              <Link to="/">
                <img
                  alt="logo"
                  src={PaidyLogoWhiteSvg}
                  width={100}
                  height={28}
                />
              </Link>
              <button className={styles.menu} onClick={onClickMenu} />
            </nav>
            <ul className={styles.links}>
              {HEADER_MENU.map((menu) => (
                <Link
                  key={menu.number}
                  to={menu.to}
                  onClick={onClickMenu}
                  className={styles.link}
                >
                  <Box display="flex" alignItems="center">
                    <p className={styles.number}>{menu.number}</p>
                    {menu.isNew && (
                      <Box ml={1}>
                        <PinkBadge />
                      </Box>
                    )}
                  </Box>
                  <p className={styles.text}>{menu.text}</p>
                  <img
                    alt="border-linear-gradient"
                    src={BorderLinearGradientImg}
                    className={styles.linearBorder}
                  />
                </Link>
              ))}
            </ul>
            <div className={styles.bottom}>
              <OutboundLink
                className={styles.shareBtn}
                href={TWITTER_URL_SHARE}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>SHARE</span>
                <img
                  alt="twitter"
                  src={TwitterWhiteSvg}
                  width={20}
                  height={16}
                />
              </OutboundLink>
              <BadgesByDevice appUrlsSp={APP_URLS_SP} appUrlsPc={APP_URLS_PC} />
            </div>
          </header>
        )}
      </Hidden>

      <section className={styles.sectionBanner}>
        <Container className={styles.container}>
          <Hidden xsDown>
            <img
              className={styles.banner}
              alt="banner"
              src={BannerImg}
              height={400}
            />
          </Hidden>
          <Hidden smUp>
            <img className={styles.banner} alt="banner" src={BannerSpImg} />
          </Hidden>
          <div className={styles.bottom}>
            <img
              className={styles.scroll}
              alt="scroll"
              src={ScrollImg}
              width={17}
              height={120}
            />
            <BadgesByDevice
              appUrlsSp={APP_URLS_SP}
              appUrlsPc={APP_URLS_PC}
              className={styles.badges}
            />
            <p className={styles.text}>
              今すぐ手に入れて来月払うあなたも、
              <br />
              手数料無料*の分割あと払いのあなたも、
              <br />
              ペイディならどう払っても大正解！
            </p>
            <p className={styles.textDisclaim}>*口座振替・銀行振込のみ無料</p>
          </div>
        </Container>
      </section>

      <div className={styles.section0102Wrapper}>
        <section className={styles.section01} id={HEADER_MENU[0].id}>
          <TitleSection
            number={HEADER_MENU[0].number}
            text={HEADER_MENU[0].text}
          />
          <button
            onClick={onClickPlayVideo(VIDEO_1_EMBEDDED)}
            className={cx(styles.videoContainer, styles.videoContainer1)}
          >
            <video
              autoPlay
              muted
              width={660}
              height={372}
              className={styles.video1}
              loop
              playsInline
            >
              <source src={Video1ThumbnailVideo} type="video/mp4" />
              <track kind="captions" />
            </video>
            <div className={styles.overlay}>
              <div className={styles.shadow}>
                <p className={styles.text}>
                  ｢ペイディならどう払っても大正解」篇{' '}
                </p>
                <div className={styles.playBtnWrapper}>
                  <img
                    alt="play-video-btn"
                    src={PlayVideoBtnLgImg}
                    width={96}
                    height={96}
                    className={styles.btn}
                  />
                </div>
              </div>
            </div>
          </button>
          <div className={styles.twoVideos}>
            <button
              onClick={onClickPlayVideo(VIDEO_2_EMBEDDED)}
              className={styles.videoContainer}
            >
              <img
                alt="video-2"
                src={Video2ThumbnailImg}
                width={326}
                height={180}
                className={styles.thumbnail}
              />
              <div className={styles.overlay}>
                <div className={styles.shadow}>
                  <p className={cx(styles.text, styles.textSmall)}>
                    ｢今手に入れて <br />
                    支払いは来月」篇
                  </p>
                  <div className={styles.playBtnWrapper}>
                    <img
                      alt="play-video-btn"
                      src={PlayVideoBtnImg}
                      width={64}
                      height={64}
                      className={styles.btnSmall}
                    />
                  </div>
                </div>
              </div>
            </button>
            <button
              onClick={onClickPlayVideo(VIDEO_3_EMBEDDED)}
              className={styles.videoContainer}
            >
              <img
                alt="video-3"
                src={Video3ThumbnailImg}
                width={326}
                height={180}
                className={styles.thumbnail}
              />
              <div className={styles.overlay}>
                <div className={styles.shadow}>
                  <p className={cx(styles.text, styles.textSmall)}>
                    ｢手数料0円で
                    <br />
                    分割払い」篇
                  </p>
                  <div className={styles.playBtnWrapper}>
                    <img
                      alt="play-video-btn"
                      src={PlayVideoBtnImg}
                      width={64}
                      height={64}
                      className={styles.btnSmall}
                    />
                  </div>
                </div>
              </div>
            </button>
          </div>
        </section>

        <section id={HEADER_MENU[1].id} className={styles.sectionCast}>
          <TitleSection
            number={HEADER_MENU[1].number}
            text={HEADER_MENU[1].text}
            description={HEADER_MENU[1].description}
            newText={HEADER_MENU[1].newText}
          />
          <div className={styles.castWrapper}>
            <div className={styles.castCard}>
              <img
                className={styles.castCardImage}
                src={Cast1Avartar}
                alt="ゆりやんレトリィバァ"
              />
              <div className={styles.castCardTextContent}>
                <h2>ゆりやんレトリィバァ</h2>
                <p>
                  1990年11月1日生まれ <br />
                  奈良県出身 <br />
                  お笑い芸人
                </p>
              </div>
            </div>
            <div className={styles.castCard}>
              <img
                className={styles.castCardImage}
                src={Cast2Avartar}
                alt="満島 真之介"
              />
              <div className={styles.castCardTextContent}>
                <h2>満島 真之介</h2>
                <p>
                  1989年5月30日生まれ
                  <br />
                  沖縄県出身 <br />
                  俳優
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section id={HEADER_MENU[2].id} className={styles.sectionSpecialMovie}>
        <TitleSection
          number={HEADER_MENU[2].number}
          text={HEADER_MENU[2].text}
          description={HEADER_MENU[2].description}
          newText={HEADER_MENU[2].newText}
        />
        <Box mt={4} />
        <button
          onClick={onClickPlayVideo(SPECIAL_MOVIE_EMBEDDED_1)}
          className={cx(styles.videoContainer, styles.videoContainer1)}
        >
          <video
            autoPlay
            muted
            width={662}
            height={372}
            className={styles.video1}
            loop
            playsInline
          >
            <source src={SpecialMovieThumbnail1Video} type="video/mp4" />
            <track kind="captions" />
          </video>
          <div className={styles.overlay}>
            <div className={styles.shadow}>
              <Typography className={styles.text}>メイキング動画</Typography>
              <img
                alt="play-video-btn"
                src={PlayVideoBtnLgImg}
                width={96}
                height={96}
                className={styles.btn}
              />
            </div>
          </div>
        </button>

        <button
          onClick={onClickPlayVideo(SPECIAL_MOVIE_EMBEDDED_2)}
          className={cx(styles.videoContainer, styles.videoContainer1)}
        >
          <img
            width={662}
            height={372}
            alt="special-movie-2"
            className={styles.video1}
            src={SpecialMovieThumbnail2Img}
          />

          <div className={styles.overlay}>
            <div className={styles.shadow}>
              <Typography className={styles.text}>インタビュー動画</Typography>
              <img
                alt="play-video-btn"
                src={PlayVideoBtnLgImg}
                width={96}
                height={96}
                className={styles.btn}
              />
            </div>
          </div>
        </button>
      </section>

      <SectionCampaign
        id={HEADER_MENU[3].id}
        number={HEADER_MENU[3].number}
        text={HEADER_MENU[3].text}
      />

      <section className={styles.section02} id={HEADER_MENU[4].id}>
        <TitleSection
          number={HEADER_MENU[4].number}
          text={HEADER_MENU[4].text}
        />
        <p className={styles.description}>
          AmazonやSHEINのほか、Visaマークのあるお店ならどこでも、スマホだけで今すぐお買い物ができるあと払いサービスです。お支払いは翌月でOK。さらに、リアルカードを発行すると、Visaマークのある街のお店でもペイディが利用可能に。{' '}
        </p>
        <div className={styles.whatPaidyItems}>
          <Slider {...SLIDER_SETTINGS} className={styles.slider}>
            {ITEMS.map((item) => (
              <div key={item.key} className={styles.whatPaidyItem}>
                {item.ImgComponent()}
              </div>
            ))}
          </Slider>
        </div>
        <ButtonOutboundLink
          className={styles.btn}
          href="https://paidy.com/shop"
          target="_blank"
          rel="noopener noreferrer"
        >
          使えるお店を探す
        </ButtonOutboundLink>
      </section>

      <section className={styles.sectionMultipayBanner}>
        <Box
          display="flex"
          gridGap={32}
          justifyContent="center"
          flexWrap="wrap"
        >
          <Box>
            <OutboundLink
              href="https://paidy.com/npay/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                alt="card-1"
                width={311}
                height={420}
                src={require('src/images/daiseikaicampaign_202404/section/multipay/card1.png')}
              />
            </OutboundLink>
            {/* <p className={styles.text}>*口座振替・銀行振込のみ無料</p> */}
          </Box>
          <Box>
            <OutboundLink
              href="https://www.apple.com/jp/shop/buy-iphone/iphone-16?cid=aos-jp-aff-bp-paidy-tvcm-event "
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                alt="card-2"
                width={311}
                height={420}
                src={require('src/images/daiseikaicampaign_202404/section/multipay/card2.png')}
              />
            </OutboundLink>
          </Box>
          <Box>
            <OutboundLink
              href="https://paidy.com/paidycard/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                alt="card-2"
                width={311}
                height={420}
                src={require('src/images/daiseikaicampaign_202404/section/multipay/card3.png')}
              />
            </OutboundLink>
          </Box>
        </Box>
      </section>

      <section className={styles.sectionQR}>
        <Hidden xsDown>
          <img alt="qr" src={QrImg} width={92} height={92} />
        </Hidden>
        <div>
          <Hidden xsDown>
            <h3 className={styles.textBadges}>アプリダウンロードはこちら</h3>
          </Hidden>
          <BadgesByDevice appUrlsSp={APP_URLS_SP} appUrlsPc={APP_URLS_PC} />
        </div>
        <OutboundLink
          className={styles.shareBtn}
          href={TWITTER_URL_SHARE}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>SHARE</span>
          <img alt="twitter" src={TwitterGreySvg} width={20} height={20} />
        </OutboundLink>
      </section>

      <SectionCampaignFooter isThemeBlack />
    </Layout>
  );
}

import React from 'react';
import { PinkBadge } from 'src/components';
import BorderLinearGradientImg from 'src/images/campaign-202303/border-linear-gradient.png';
import styles from './TitleSection.module.scss';

export default function TitleSection({ number, text, description, newText }) {
  return (
    <div className={styles.wrapper}>
      {number && <h2 className={styles.number}>{number}</h2>}
      <h3 className={styles.text}>{text}</h3>
      <img
        alt="border-linear-gradient"
        src={BorderLinearGradientImg}
        width={93}
        className={styles.linearBorder}
      />
      {newText && (
        <PinkBadge size="md" className={styles.pinkText}>
          {newText}
        </PinkBadge>
      )}
      {description && <p className={styles.description}>{description}</p>}
    </div>
  );
}
